//https://coolors.co/b1a495-071013-5c5d8d-9b3d12-34623f

@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600&family=Trirong:wght@100;200;300;400;500&display=swap');

$headingFont: 'Maitree', serif;
$bodyFont: 'Nunito Sans', sans-serif;
$subheadingFont: 'Trirong', serif;

// Logo SVG
#Layer_2 {
    width: 100%;

    max-width: 280px;

    g {
        #Layer_1-2 {
            width: 100%;

            .cls-1 {
                stroke-width: 0px;
            }


        }
    }

    line {
        &.cls-2 {
            fill: none;
            stroke: #4368d1;
            stroke-miterlimit: 10;
            stroke-width: .5px;
        }
    }
}



// Reset
* {
    box-sizing: border-box;
}

// Colours
$white: #ffffff;
$black: #000000;
$off_white: #f2f2f2;
$primary: #2d4892;
$secondary: #654f6f;
$tertiary: #5c5d8d;
$quaternary: #9b3d12;
$quinary: #34623f;


@mixin for-size($size) {
    @if $size ==phone-only {
        @media (width <=767px) {
            @content;
        }
    }

    @else if $size ==tablet-portrait {
        @media (768px <=width <=834px) and (orientation:portrait) {
            @content;
        }
    }

    @else if $size ==tablet-landscape {
        @media (835px <=width <=1024px) and (orientation:landscape) {
            @content;
        }
    }

    @else if $size ==lg-desktop {
        @media (width >=1024px) {
            @content;
        }
    }

    @else if $size ==xl-desktop {
        @media (width >=1400px) {
            @content;
        }
    }

    @else if $size ==xxl-desktop {
        @media (width >=1800px) {
            @content;
        }
    }

    @else if $size ==xxxl-desktop {
        @media (width >=2500px) {
            @content;
        }
    }

    @else if $size ==ultrawide-desktop {
        @media (width >=3800px) {
            @content;
        }
    }
}

@mixin for-sizes($sizes...) {
    @each $size in $sizes {
        @include for-size($size) {
            @content;
        }
    }
}

body {
    background-color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
blockquote,
cite,
hr,
span {
    color: #4F4538;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    white-space: balance;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    line-height: 1.5;
}

h1 {
    font-family: $headingFont;
}

h2,
h3,
h4,
h5,
h6 {
    font-family: $subheadingFont;
}

p,
li,
span {
    white-space: balance;
    font-family: $bodyFont;
    font-size: 18px;
}

h1 {
    --minFontSize: 30px;
    --maxFontSize: 55px;
    --scaler: 10vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    font-weight: 700;
    line-height: 1.2;
}

.container {
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 1600px;
    min-height: 100%;

    @include for-sizes(phone-only, tablet-portrait, tablet-landscape, lg-desktop) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

hr {
    &.fixed_width {
        max-width: 200px;
        margin: 0;
        margin-bottom: 20px;
        display: block;
    }
}

ul {
    list-style: none;
    padding-inline-start: 0px;

    li {
        // font-size: 16px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 1.5em;

        /* space to preserve indentation on wrap */
        &:before {
            font-family: fontAwesome;
            content: "\f0e3";
            position: absolute;
            left: 0;
            top: 0px;
            width: 1em;
            height: 1em;
        }
    }

    &.social_list {
        display: inline-flex;

        flex-direction: row;
        margin: auto;
        gap: 10px;

        li {
            font-size: 30px;
            margin-bottom: 0;
            position: relative;
            padding-left: 0;

            /* space to preserve indentation on wrap */
            &:before {
                display: none;
            }

            a {
                color: $black;
            }
        }
    }
}

header {
    &.header_area {
        background: $white;
        padding-top: 20px;
        padding-bottom: 20px;

        .header_container {
            display: grid;
            grid-template-columns: 300px 1fr 300px;
            align-items: center;

            @include for-size(phone-only) {
                grid-template-columns: 1fr 1fr;
                grid-template-areas: "logo_area contact_details" "nav nav";
                gap: 10px 10px;
            }

            .header_logo {
                @include for-size(phone-only) {
                    grid-area: logo_area;
                }

                a {
                    color: $black;
                    text-decoration: none;

                    img {
                        max-width: 100%;
                    }

                    &.textLogo {
                        span {
                            margin: 0;
                            padding: 0;
                            font-size: 25px;
                            display: block;
                            font-weight: 600;
                            text-wrap: nowrap;
                        }

                        small {
                            margin: 0;
                            padding: 0;
                            padding-top: 5px;
                            display: block;
                            text-wrap: nowrap;
                        }
                    }
                }
            }

            .header_navigation {
                @include for-size(phone-only) {
                    grid-area: nav;
                }

                nav {
                    margin: auto;
                    text-align: center;

                    ul {
                        &.inlineNav {
                            list-style-type: none;
                            padding-inline-start: 0;
                            margin: 0;
                            display: inline-flex;
                            align-items: center;
                            flex-direction: row;

                            li {
                                &:before {
                                    display: none;
                                }

                                padding-left:0;
                                margin-bottom:0;

                                a {
                                    color: $black;
                                    font-size: 16px;
                                    font-weight: 700;
                                    text-transform: uppercase;
                                    padding: 10px 20px;
                                    display: inline-block;
                                    transition: all 0.3s ease-in-out;
                                    text-decoration: none;

                                    @include for-size(phone-only) {
                                        padding: 10px;
                                    }

                                    &:hover {
                                        color: #3d3d3d;
                                    }

                                    &.active {
                                        border-bottom: 2px solid $primary;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            .header_contact {
                @include for-size(phone-only) {
                    grid-area: contact_details;
                }

                a {
                    display: block;
                    text-align: right;
                    color: $black;
                    text-decoration: none;

                    &.telNo {
                        font-size: 23px;
                        font-weight: 600;

                        @include for-size(phone-only) {
                            font-size: 20px;
                        }
                    }

                    &.emailAddress {
                        font-size: 14px;

                        @include for-size(phone-only) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

footer {
    &.siteFooter {
        .siteFooter_container {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1.5fr;
            gap: 0px 30px;
            align-items: flex-end;
            padding-top: 40px;
            padding-bottom: 40px;

            @include for-size(phone-only) {
                grid-template-columns: 1fr;
            }



            .siteFooter_container__left {
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                color: $black;
                padding-top: 20px;
                padding-bottom: 20px;

                hr {
                    color: $primary;
                    max-width: 200px;
                    text-align: left;
                    margin: 10px 0;

                }

                p {
                    margin-bottom: 0;
                }

                @include for-size(phone-only) {
                    text-align: center;
                }
            }

            .siteFooter_container__middle {
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                color: $black;
                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                flex-direction: column;

                a {
                    color: $black;
                    text-decoration: none;

                    &.priv_policy {

                        &:hover {
                            text-decoration: underline;
                            text-decoration-color: $primary;
                            text-decoration-thickness: 2px;
                            text-underline-offset: 10px;
                        }
                    }
                }
            }

            .siteFooter_container__right {
                text-align: right;
                font-size: 14px;
                font-weight: 400;
                color: $black;
                padding-top: 20px;
                padding-bottom: 20px;

                a {
                    color: $black;
                    text-decoration: none;
                }
                p{
                    &.madeBy{
                        font-size:14px;
                    }
                }
                @include for-size(phone-only) {
                    text-align: center;
                    p{
                        &.madeBy{
                            font-size:12px;
                        }
                    }
                }
            }
        }
    }
}

#home {
    section {}
}

section {

    &.hero_area {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        isolation: isolate;
        position: relative;
        padding-top: 180px;
        padding-bottom: 180px;

        @include for-size(phone-only) {
            height: auto;
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: -1;
        }

        .hero_container_parent {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            gap: 0px 30px;
            height: 100%;
            z-index: 10;
            vertical-align: middle;

            @include for-size(phone-only) {
                grid-template-columns: 1fr;
                gap: 0px;
                height: auto;
                padding-top: 60px;
                padding-bottom: 60px;
            }

            .hero_left {
                h1 {
                    color: $white;
                }

                h3 {
                    color: $white;
                    font-size: 25px;
                }
            }

            .hero_right {}
        }
    }

    &.main_content {
        padding-top: 50px;
        padding-bottom: 50px;

        .main_content_container__parent {
            display: grid;
            grid-template-columns: 1fr 0.5fr;
            gap: 0px 30px;

            @include for-size(phone-only) {
                grid-template-columns: 1fr;
                gap: 0px;
            }

            .main_content_container__left {
                h2 {
                    font-size: 35px;
                    font-weight: 700;
                    margin-bottom: 20px;

                }
            }

            .main_content_container__right {
                background: #f8f8f8;
                display: flex;
                align-items: center;
                padding: 20px;

                @include for-size(phone-only) {
                    padding: 0px;
                    padding-top: 40px;
                    padding-bottom: 40px;
                    margin-top: 30px;
                }

                blockquote {
                    @include for-size(phone-only) {
                        padding: 0px;
                        margin-block-start: 0px;
                        margin-block-end: 0px;
                        margin-inline-start: 20px;
                        margin-inline-end: 20px;
                    }

                    p {
                        font-size: 23px;
                        font-weight: 700;
                        margin-top: 0;
                        margin-bottom: 20px;
                    }

                    cite {
                        font-size: 20px;
                        font-weight: 400;
                        margin-bottom: 20px;
                        font-style: normal;

                        &:before {
                            content: "- ";
                        }
                    }
                }
            }
        }
    }

    &.core_services {
        background: #f8f8f8;
        padding-top: 50px;
        padding-bottom: 50px;

        h2 {
            font-size: 35px;
            text-align: center;
            color: #4F4538;
        }

        hr {
            margin: auto;
            margin-bottom: 60px;
            color: $primary;
        }

        .core_services_container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0 30px;

            @include for-size(phone-only) {
                grid-template-columns: 1fr;
                gap: 20px 0px;
            }

            .core_services_container__item {
                i {
                    &.intoIcon {
                        font-size: 40px;
                        margin: auto;
                        margin-bottom: 20px;

                        display: block;
                        color: $primary;
                    }
                }

                h3 {
                    font-size: 25px;
                    color: #4F4538;
                }

                ul {
                    li {
                        color: #4F4538;
                    }
                }
            }
        }
    }
}

#services,
#about,
#privacy-policy {
    .main_content_container__right {
        background: none;
    }
}




form {
    &.contact_form {

        input,
        textarea,
        select {
            padding: 10px;
            display: block;
            width: 100%;
            margin-top: 10px;
            border-radius: 0;
            font-size: 16px;
            border: 1px solid #ccc;
            font-family: $bodyFont;
        }

        textarea {
            min-height: 200px;
        }

        p {
            &.error {
                background: red;
                color: white;
                display: block;
                font-size: 14px;
                margin-bottom: 10px;
                text-align: center;
                padding: 5px;
                margin-top: 0;
                text-transform: uppercase;
            }

            &.success {
                background: green;
                color: white;
                display: block;
                font-size: 16px;
                text-align: center;
                padding: 5px;
            }
        }

        .book_appointment {
            display: inline-block;
            padding-bottom: 20px;

            label {
                display: inline-flex;
                align-items: center;
                margin-right: 30px;

                input {
                    &[type="radio"] {
                        margin: 0;
                        margin-right: 10px;
                    }
                }
            }
        }

        button {
            &.cta_btn {
                padding: 10px 40px;
                background: $primary;
                color: $white;
                border: none;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                display: block;
                margin: auto;
                margin-top: 20px;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
            }
        }
    }
}

// .error {
//     color: red;
//     font-size: 0.8em;
//     margin-top: 5px;
// }

// .success {
//     color: green;
//     font-size: 1em;
//     margin-bottom: 15px;
// }