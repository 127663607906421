@import url("https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600&family=Trirong:wght@100;200;300;400;500&display=swap");
#Layer_2 {
  width: 100%;
  max-width: 280px;
}
#Layer_2 g #Layer_1-2 {
  width: 100%;
}
#Layer_2 g #Layer_1-2 .cls-1 {
  stroke-width: 0px;
}
#Layer_2 line.cls-2 {
  fill: none;
  stroke: #4368d1;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
blockquote,
cite,
hr,
span {
  color: #4F4538;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  white-space: balance;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  line-height: 1.5;
}

h1 {
  font-family: "Maitree", serif;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Trirong", serif;
}

p,
li,
span {
  white-space: balance;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
}

h1 {
  --minFontSize: 30px;
  --maxFontSize: 55px;
  --scaler: 10vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 700;
  line-height: 1.2;
}

.container {
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 1600px;
  min-height: 100%;
}
@media (width <= 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (768px <= width <= 834px) and (orientation: portrait) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (835px <= width <= 1024px) and (orientation: landscape) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (width >= 1024px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

hr.fixed_width {
  max-width: 200px;
  margin: 0;
  margin-bottom: 20px;
  display: block;
}

ul {
  list-style: none;
  padding-inline-start: 0px;
}
ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 1.5em;
  /* space to preserve indentation on wrap */
}
ul li:before {
  font-family: fontAwesome;
  content: "\f0e3";
  position: absolute;
  left: 0;
  top: 0px;
  width: 1em;
  height: 1em;
}
ul.social_list {
  display: inline-flex;
  flex-direction: row;
  margin: auto;
  gap: 10px;
}
ul.social_list li {
  font-size: 30px;
  margin-bottom: 0;
  position: relative;
  padding-left: 0;
  /* space to preserve indentation on wrap */
}
ul.social_list li:before {
  display: none;
}
ul.social_list li a {
  color: #000000;
}

header.header_area {
  background: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}
header.header_area .header_container {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  align-items: center;
}
@media (width <= 767px) {
  header.header_area .header_container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "logo_area contact_details" "nav nav";
    gap: 10px 10px;
  }
}
@media (width <= 767px) {
  header.header_area .header_container .header_logo {
    grid-area: logo_area;
  }
}
header.header_area .header_container .header_logo a {
  color: #000000;
  text-decoration: none;
}
header.header_area .header_container .header_logo a img {
  max-width: 100%;
}
header.header_area .header_container .header_logo a.textLogo span {
  margin: 0;
  padding: 0;
  font-size: 25px;
  display: block;
  font-weight: 600;
  text-wrap: nowrap;
}
header.header_area .header_container .header_logo a.textLogo small {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  display: block;
  text-wrap: nowrap;
}
@media (width <= 767px) {
  header.header_area .header_container .header_navigation {
    grid-area: nav;
  }
}
header.header_area .header_container .header_navigation nav {
  margin: auto;
  text-align: center;
}
header.header_area .header_container .header_navigation nav ul.inlineNav {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}
header.header_area .header_container .header_navigation nav ul.inlineNav li {
  padding-left: 0;
  margin-bottom: 0;
}
header.header_area .header_container .header_navigation nav ul.inlineNav li:before {
  display: none;
}
header.header_area .header_container .header_navigation nav ul.inlineNav li a {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 20px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
@media (width <= 767px) {
  header.header_area .header_container .header_navigation nav ul.inlineNav li a {
    padding: 10px;
  }
}
header.header_area .header_container .header_navigation nav ul.inlineNav li a:hover {
  color: #3d3d3d;
}
header.header_area .header_container .header_navigation nav ul.inlineNav li a.active {
  border-bottom: 2px solid #2d4892;
}
@media (width <= 767px) {
  header.header_area .header_container .header_contact {
    grid-area: contact_details;
  }
}
header.header_area .header_container .header_contact a {
  display: block;
  text-align: right;
  color: #000000;
  text-decoration: none;
}
header.header_area .header_container .header_contact a.telNo {
  font-size: 23px;
  font-weight: 600;
}
@media (width <= 767px) {
  header.header_area .header_container .header_contact a.telNo {
    font-size: 20px;
  }
}
header.header_area .header_container .header_contact a.emailAddress {
  font-size: 14px;
}
@media (width <= 767px) {
  header.header_area .header_container .header_contact a.emailAddress {
    font-size: 12px;
  }
}

footer.siteFooter .siteFooter_container {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr;
  gap: 0px 30px;
  align-items: flex-end;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (width <= 767px) {
  footer.siteFooter .siteFooter_container {
    grid-template-columns: 1fr;
  }
}
footer.siteFooter .siteFooter_container .siteFooter_container__left {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}
footer.siteFooter .siteFooter_container .siteFooter_container__left hr {
  color: #2d4892;
  max-width: 200px;
  text-align: left;
  margin: 10px 0;
}
footer.siteFooter .siteFooter_container .siteFooter_container__left p {
  margin-bottom: 0;
}
@media (width <= 767px) {
  footer.siteFooter .siteFooter_container .siteFooter_container__left {
    text-align: center;
  }
}
footer.siteFooter .siteFooter_container .siteFooter_container__middle {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}
footer.siteFooter .siteFooter_container .siteFooter_container__middle a {
  color: #000000;
  text-decoration: none;
}
footer.siteFooter .siteFooter_container .siteFooter_container__middle a.priv_policy:hover {
  text-decoration: underline;
  text-decoration-color: #2d4892;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
}
footer.siteFooter .siteFooter_container .siteFooter_container__right {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}
footer.siteFooter .siteFooter_container .siteFooter_container__right a {
  color: #000000;
  text-decoration: none;
}
footer.siteFooter .siteFooter_container .siteFooter_container__right p.madeBy {
  font-size: 14px;
}
@media (width <= 767px) {
  footer.siteFooter .siteFooter_container .siteFooter_container__right {
    text-align: center;
  }
  footer.siteFooter .siteFooter_container .siteFooter_container__right p.madeBy {
    font-size: 12px;
  }
}

section.hero_area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  isolation: isolate;
  position: relative;
  padding-top: 180px;
  padding-bottom: 180px;
}
@media (width <= 767px) {
  section.hero_area {
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
section.hero_area:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
section.hero_area .hero_container_parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 0px 30px;
  height: 100%;
  z-index: 10;
  vertical-align: middle;
}
@media (width <= 767px) {
  section.hero_area .hero_container_parent {
    grid-template-columns: 1fr;
    gap: 0px;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
section.hero_area .hero_container_parent .hero_left h1 {
  color: #ffffff;
}
section.hero_area .hero_container_parent .hero_left h3 {
  color: #ffffff;
  font-size: 25px;
}
section.main_content {
  padding-top: 50px;
  padding-bottom: 50px;
}
section.main_content .main_content_container__parent {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 0px 30px;
}
@media (width <= 767px) {
  section.main_content .main_content_container__parent {
    grid-template-columns: 1fr;
    gap: 0px;
  }
}
section.main_content .main_content_container__parent .main_content_container__left h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
}
section.main_content .main_content_container__parent .main_content_container__right {
  background: #f8f8f8;
  display: flex;
  align-items: center;
  padding: 20px;
}
@media (width <= 767px) {
  section.main_content .main_content_container__parent .main_content_container__right {
    padding: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 30px;
  }
}
@media (width <= 767px) {
  section.main_content .main_content_container__parent .main_content_container__right blockquote {
    padding: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }
}
section.main_content .main_content_container__parent .main_content_container__right blockquote p {
  font-size: 23px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
}
section.main_content .main_content_container__parent .main_content_container__right blockquote cite {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  font-style: normal;
}
section.main_content .main_content_container__parent .main_content_container__right blockquote cite:before {
  content: "- ";
}
section.core_services {
  background: #f8f8f8;
  padding-top: 50px;
  padding-bottom: 50px;
}
section.core_services h2 {
  font-size: 35px;
  text-align: center;
  color: #4F4538;
}
section.core_services hr {
  margin: auto;
  margin-bottom: 60px;
  color: #2d4892;
}
section.core_services .core_services_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 30px;
}
@media (width <= 767px) {
  section.core_services .core_services_container {
    grid-template-columns: 1fr;
    gap: 20px 0px;
  }
}
section.core_services .core_services_container .core_services_container__item i.intoIcon {
  font-size: 40px;
  margin: auto;
  margin-bottom: 20px;
  display: block;
  color: #2d4892;
}
section.core_services .core_services_container .core_services_container__item h3 {
  font-size: 25px;
  color: #4F4538;
}
section.core_services .core_services_container .core_services_container__item ul li {
  color: #4F4538;
}

#services .main_content_container__right,
#about .main_content_container__right,
#privacy-policy .main_content_container__right {
  background: none;
}

form.contact_form input,
form.contact_form textarea,
form.contact_form select {
  padding: 10px;
  display: block;
  width: 100%;
  margin-top: 10px;
  border-radius: 0;
  font-size: 16px;
  border: 1px solid #ccc;
  font-family: "Nunito Sans", sans-serif;
}
form.contact_form textarea {
  min-height: 200px;
}
form.contact_form p.error {
  background: red;
  color: white;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  padding: 5px;
  margin-top: 0;
  text-transform: uppercase;
}
form.contact_form p.success {
  background: green;
  color: white;
  display: block;
  font-size: 16px;
  text-align: center;
  padding: 5px;
}
form.contact_form .book_appointment {
  display: inline-block;
  padding-bottom: 20px;
}
form.contact_form .book_appointment label {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
}
form.contact_form .book_appointment label input[type=radio] {
  margin: 0;
  margin-right: 10px;
}
form.contact_form button.cta_btn {
  padding: 10px 40px;
  background: #2d4892;
  color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  margin: auto;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}/*# sourceMappingURL=layout.css.map */